

import React, { useState } from 'react';

function CarouselTextImage  ()  {
const slides2 = document.querySelectorAll('.slide');
const arrows2 = document.querySelectorAll('.arrow2');
const inputs2 = document.querySelectorAll('.input');
let currentIndex = 0;
let touchStartX = 0;
let touchEndX = 0;

function showSlide(index) {
  slides2.forEach((slide, i) => {
    slide.style.display = i === index ? 'flex' : 'none';
  });
}

function changeInputColor(index) {
  inputs2.forEach((input, i) => {
    input.classList.toggle('black', i === index);
  });
}

function updateCarousel() {
  showSlide(currentIndex);
  changeInputColor(currentIndex);
}

function nextSlide() {
  currentIndex++;
  if (currentIndex >= slides2.length) {
    currentIndex = 0;
  }
  updateCarousel();
}

function previousSlide() {
  currentIndex--;
  if (currentIndex < 0) {
    currentIndex = slides2.length - 1;
  }
  updateCarousel();
}

arrows2.forEach((arrow) => {
  arrow.addEventListener('click', () => {
    if (arrow.classList.contains('arrowLeft2')) {
      previousSlide();
    } else if (arrow.classList.contains('arrowRight2')) {
      nextSlide();
    }
  });
});
updateCarousel();

function handleTouchStart(event) {
  touchStartX = event.touches[0].clientX;
}


function handleTouchEnd(event) {
  touchEndX = event.changedTouches[0].clientX;
  handleSwipe();
}


function handleSwipe() {
  const swipeThreshold = 50; 

  if (touchEndX - touchStartX > swipeThreshold) {
    
    previousSlide();
  } else if (touchStartX - touchEndX > swipeThreshold) {

    nextSlide();
  }
}


slides2.forEach((slide) => {
  slide.addEventListener('touchstart', handleTouchStart);
  slide.addEventListener('touchend', handleTouchEnd);
});


updateCarousel();

  return (
    <div>
<div className="carouselContainer">
          <div className="carousel">
            <div className="slide">
              <div className="mobileSlide">
                <img src="../img/“”.svg" className="aspas" alt="A imagem é um desenho de uma aspas"></img>
              <p className="fraseCliente">"Estamos absolutamente encantados com o trabalho da arquiteta. Seu talento e criatividade brilharam em cada detalhe do nosso projeto. Ela superou nossas expectativas!"
</p>
              <p className="cliente">R.R.N</p>
            </div>
              <img src="../img/imagem-cliente.jpg" alt="A imagem retrata uma sala de estar com um sofá da cor bege, uma cortina até o chão, um quadro grnade na parede um desenho abstrato, cadeiras ao retor da mesa de centro pequena, banquinhos rústicos" className="imagemCliente"></img>
            
            </div>
            <div className="slide">
              <div className="mobileSlide">
                <img src="../img/“”.svg" className="aspas2" alt="A imagem é um desenho de uma aspas"></img>
              <p className="fraseCliente2">"A arquiteta não apenas entregou nosso projeto no prazo, mas também o fez com a máxima qualidade. Seu profissionalismo é notável.</p>
              <p className="cliente">M.H.A</p>
            </div>
            <img src="../img/imagem-cliente2.jpg" alt="A imagem retrata uma sala de estar com um sofá da cor bege, uma cortina até o chão, um quadro grnade na parede um desenho abstrato, cadeiras ao retor da mesa de centro pequena, banquinhos rústicos" className="imagemCliente2"></img>
            </div>

            <div className="slide">
              <div className="mobileSlide">
                <img src="../img/“”.svg" className="aspas3" alt="A imagem é um desenho de uma aspas"></img>
              <p className="fraseCliente3">"O gerenciamento de obra da arquiteta foi impecável. Ela manteve tudo sob controle, garantindo que tudo fluísse sem problemas e dentro do orçamento."
</p>
              <p className="cliente">N.A.M</p>
            </div>
            <img src="../img/imagem-cliente3.jpeg" alt="A imagem retrata uma sala de estar com um sofá da cor bege, uma cortina até o chão, um quadro grnade na parede um desenho abstrato, cadeiras ao retor da mesa de centro pequena, banquinhos rústicos" className="imagemCliente3"></img>
            </div>

            <div className="slide">
              <div className="mobileSlide">
                <img src="../img/“”.svg" className="aspas4" alt="A imagem é um desenho de uma aspas"></img>
              <p className="fraseCliente4">"É raro encontrar uma arquiteta que seja tão dedicada e comprometida como ela. Ela realmente se importa com seus clientes e suas visões."

</p>
              <p className="cliente">K.R.N</p>
            </div>
            <img src="../img/imagem-cliente4.jpeg" alt="A imagem retrata uma sala de estar com um sofá da cor bege, uma cortina até o chão, um quadro grnade na parede um desenho abstrato, cadeiras ao retor da mesa de centro pequena, banquinhos rústicos" className="imagemCliente4"></img>
            </div>

            <div className="slide">
              <div className="mobileSlide">
                <img src="../img/“”.svg" className="aspas5" alt="A imagem é um desenho de uma aspas"></img>
              <p className="fraseCliente5">"A arquiteta não apenas transformou nossa casa, mas também nossas vidas. Seu talento e paixão pelo design são incomparáveis."
</p>
              <p className="cliente">V.A.S</p>
            </div>
            <img src="../img/imagem-cliente5.jpeg" alt="A imagem retrata uma sala de estar com um sofá da cor bege, uma cortina até o chão, um quadro grnade na parede um desenho abstrato, cadeiras ao retor da mesa de centro pequena, banquinhos rústicos" className="imagemCliente5"></img>
            </div>

            <div className="slide">
              <div className="mobileSlide">
                <img src="../img/“”.svg" className="aspas6" alt="A imagem é um desenho de uma aspas"></img>
              <p className="fraseCliente6">"O projeto que a arquiteta criou para nós é um verdadeiro reflexo do nosso estilo e personalidade. Ela captou nossos desejos de forma perfeita."
</p>
              <p className="cliente">L.M.O</p>
            </div>
            <img src="../img/imagem-cliente6.jpeg" alt="A imagem retrata uma sala de estar com um sofá da cor bege, uma cortina até o chão, um quadro grnade na parede um desenho abstrato, cadeiras ao retor da mesa de centro pequena, banquinhos rústicos" className="imagemCliente6"></img>
            </div>

            <div className="slide">
              <div className="mobileSlide">
                <img src="../img/“”.svg" className="aspas7" alt="A imagem é um desenho de uma aspas"></img>
              <p className="fraseCliente7">"Sua abordagem inovadora e visão de longo prazo tornaram o projeto uma verdadeira obra-prima. Estamos gratos por tê-la escolhido como nossa arquiteta."

</p>
              <p className="cliente">C.O.S</p>
            </div>
            <img src="../img/imagem-cliente7.jpeg" alt="A imagem retrata uma sala de estar com um sofá da cor bege, uma cortina até o chão, um quadro grnade na parede um desenho abstrato, cadeiras ao retor da mesa de centro pequena, banquinhos rústicos" className="imagemCliente7"></img>
            </div>

            <div className="slide">
              <div className="mobileSlide">
                <img src="../img/“”.svg" className="aspas8" alt="A imagem é um desenho de uma aspas"></img>
              <p className="fraseCliente8">"O compromisso da arquiteta com a excelência é evidente em cada aspecto do projeto. Ela não aceita menos do que o melhor."
</p>
              <p className="cliente">L.R.M</p>
            </div>
            <img src="../img/imagem-cliente8.jpeg" alt="A imagem retrata uma sala de estar com um sofá da cor bege, uma cortina até o chão, um quadro grnade na parede um desenho abstrato, cadeiras ao retor da mesa de centro pequena, banquinhos rústicos" className="imagemCliente8"></img>
            </div>
          </div>
          

          <div className="arrows">
          <div className="arrow2 arrowLeft2"></div>
          <div className="arrow2 arrowRight2"></div>
      </div>
        </div>
        <div className="inputContainer">
          <input type="button" className="input"></input>
          <input type="button" className="input"></input>
          <input type="button" className="input"></input>
          <input type="button" className="input"></input>
          <input type="button" className="input "></input>
          <input type="button" className="input"></input>
          <input type="button" className="input"></input>
          <input type="button" className="input"></input>
        </div>
        </div>
        
     
  )
}
export default CarouselTextImage;